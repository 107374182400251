<template>
  <div>

    <!-- 
    <v-card>
      <v-container>
        <v-row>
          <h3 class="mt-3 ml-6">REPORTES</h3>
          <v-spacer> </v-spacer>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <s-date label="Fecha Inicio" v-model="filter.DateBegin"></s-date>
          </v-col>
          <v-col>
            <s-date label="Fecha Fin" v-model="filter.DateEnd"></s-date>
          </v-col>
          <v-col>
            <s-select-definition
              label="Turno"
              :def="1278"
              v-model="filter.TypeTurn"
            ></s-select-definition>
          </v-col>
          <v-col>
            <v-btn color="info" fab @click="getDataGeneral()">
              <i class="fas fa-search"></i>
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="viewDocument">
          <v-col lg="6" class="pt-0">
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-btn color="success" fab @click="downloadDocumentExcel()"
                    ><i class="fas fa-file-excel" style="font-size: 18px"></i
                  ></v-btn>
                </v-list-item-avatar>
                <v-list-item-avatar class="mr-3">
                  <v-btn color="error" fab @click="downloadDocumentPdf()"
                    ><i class="fas fa-file-pdf" style="font-size: 18px"></i
                  ></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    ><b
                      ><a style="color: black">
                        Reporte de hidrotermico por turno</a
                      ></b
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card> -->

    <v-card class="mt-5">
      <v-container>
        <v-row>
          <v-col>
            <h3 class="mt-3 ml-6">REPORTE </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-list>           
                <v-list-item>
                  <v-list-item-avatar>
                      <v-btn x-small @click="viewDialogDownload(1)"> <v-icon>mdi-cloud-download</v-icon></v-btn>
                  </v-list-item-avatar>

                  <v-list-item-content>
                      <v-list-item-title ><b><a style="color: black"> AP-FR-151 (Tratamiento Hidrotérmico del Mango (Auditable)) </a></b></v-list-item-title>
                  </v-list-item-content>  
                </v-list-item>

               <v-list-item>
                  <v-list-item-avatar>
                      <v-btn x-small @click="viewDialogDownload(2)"> <v-icon>mdi-cloud-download</v-icon></v-btn>
                  </v-list-item-avatar>

                  <v-list-item-content>
                      <v-list-item-title ><b><a style="color: black"> Reporte Tratamiento Hidrotérmico del Mango (Reporte) </a></b></v-list-item-title>
                  </v-list-item-content>  
                </v-list-item>


            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

      	<v-dialog
            v-model="dialogDownloadHidro"
            v-if="dialogDownloadHidro"
            width="600"
        >
            <v-card>
                <v-container>
                    <v-row>
                        <s-toolbar
                            label="Descargar Reporte"
                            excel
                            @excel="FormatDocument()"
                            pdf
                            @pdf="ReportDownloadPDF"
                        >

                        </s-toolbar>
                    </v-row>
                    <v-row>
                        <!-- <v-col cols="6">
                            <s-select-definition
                                v-model="filter.TypeCultive"
                                label="Turno"
                                :def="1278"
                            >
                            </s-select-definition>
                        </v-col> -->
                        <v-col cols="6">
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                          <s-date 
                            label="Fecha Inicio"
                            v-model="filter.DateBegin" />
                        </v-col>
                        <v-col cols="6">
                          <s-date 
                            label="Fecha Fin"
                            v-model="filter.DateEnd" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        
  </div>
</template>

<script>
import _sHydrothermalReportService from "@/services/FreshProduction/Report/HydrothermalReportService";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";

export default {
  data() {
    return {
      filter: {},
      dataRecivied: [],
      report : {},
      flag: 0,
      processing : false,
      viewDocument: false,
      dialogDownloadHidro: false
    };
  },

  methods: {

    FormatDocument() {
      if(this.flag == 1) {
        let parametervalue = [];

        parametervalue.push(this.filter.DateBegin);
        parametervalue.push(this.filter.DateEnd);
       
        let parametergen = " @dateBegin,@dateEnd ";
        this.ReportDownloadExcel("AP-FR-151 (TRATAMIENTO HIDROTÉRMICO DEL MANGO)","PrfReporteHydrothermal",parametervalue,parametergen)
        
      }

      if(this.flag == 2) {

        let parametervalue = [];

        parametervalue.push(this.filter.DateBegin);
        parametervalue.push(this.filter.DateEnd);
       
        let parametergen = " @dateBegin,@dateEnd ";
        this.ReportDownloadExcel("TRATAMIENTO HIDROTÉRMICO DEL MANGO - REPORTE","PRFReports_Hidrotermis",parametervalue,parametergen)

      }

    },

    getDataGeneral() {
      this.viewDocument = true;
      console.log("datos del filtro", this.filter);
      _sHydrothermalReportService
        .PrfHydrothermalList(this.filter, this.$fun.getUserID())
        .then((r) => {
          if (r.status == 200) {
            this.dataRecivied = r.data;
            console.log("datos recibidos para exportar", this.dataRecivied);
          }
        });
    },


    ReportDownloadPDF() {

      if(this.flag == 2) {
        this.$fun.alert('Este reporte no es auditable');
        return;
      }
      console.log("this.Flag", this.flag)
      if(this.flag == 1){
         
         let obj = {};
         obj.Flag = this.flag; 
         obj.NameReport = "AP-FR-059 (RESUMEN DE REPORTE DE CALIBRADO)"; 
         obj.NameProc = "PRFReports_Hidrotermis";
         obj.DateBegin = this.filter.DateBegin;
         obj.DateEnd = this.filter.DateEnd;
 
 
         console.log("x filter", obj);
          
           _sLotProdCalibratedService
             .reportCalibratePDF(obj, this.$fun.getUserID())
             .then((r) => {
 
                  this.$fun.downloadFile(
                     r.data,
                     this.$const.TypeFile.PDF,
                     obj.NameReport
                     );
               
             });
       }
      // console.log("this.dataRecivied", this.dataRecivied);
      // _sHydrothermalReportService
      // .PrfHydrothermalReportPDF(this.filter, this.$fun.getUserID())
      // .then((resp)=>{
      //   if(resp.status== 200){
      //     this.$fun.downloadFile(
      //       resp.data,
      //       this.$const.TypeFile.PDF,
      //       "Reporte de Hidrotermico")
      //   }
      // })
    },
    viewDialogDownload(flag){
      
      this.flag = flag;
      this.dialogDownloadHidro = true;
      
    },
    ReportDownloadExcel(QryNameReport,qryname,parametervalue,parametergen){
      
      this.report.QryParamsArray = parametergen;
      this.report.QryNameReport = QryNameReport;
      this.report.QryParamsArrayValue  = parametervalue.join(",");
      this.report.QryNameProcedure  = qryname;

      this.processing = true;

      console.log('datos del reporte', this.report)

      _sQryConfigurationService.downloadexcel(this.report, this.$fun.getUserID())
      .then((r) => {
        if (r.status == 200) {
          this.processing = false;
          this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									QryNameReport);
        }
      },(e)=>{
        this.processing = true;
      });
    }, 
  },
};
</script>