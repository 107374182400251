import Service from "../../Service";

const resource = "HydrothermalReport/"

export default {

    PrfHydrothermalList(obj, requestID) {
        return Service.post(resource + "PrfHydrothermalList", obj, {
            params: { requestID },
        });
    },

    PrfHydrothermalReportExcel(obj, requestID) {

        return Service.post(resource + "PrfHydrothermalReportExcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID },
        });
    },

    PrfHydrothermalReportPDF(obj, requestID) {
        return Service.post(resource + "PrfHydrothermalReportPDF", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID },
        });
    }

}